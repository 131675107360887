export const customInputTypes = [
  {
    id: 'Service List',
    label: 'Service List'
  },
  {
    id: 'Database List',
    label: 'Database List'
  },
  {
    id: 'About the company',
    label: 'About the company'
  },
  {
    id: 'About the Team',
    label: 'About the Team'
  },
  {
    id: 'Team List',
    label: 'Team List'
  },
  {
    id: 'Others',
    label: 'Others'
  }
];
