import React, { useRef } from 'react';
import { CircularProgress, Tooltip } from '@mui/material';
import { InfoRounded, UploadRounded } from '@mui/icons-material';
import UploadError from './UploadError';
import useUpload from '../../../hooks/useUpload';
import UploadedFiles from './UploadedFiles';

const Uploader = ({ title, prefix = '', tooltip = '' }) => {
  const inputRef = useRef(null);
  const { setSelectedFiles, errorMessage, uploading, uploadResults, uploadedFiles, fetchingFiles } =
    useUpload(prefix);

  const handleInputClick = () => {
    inputRef.current.click();
  };

  const handleFileChange = event => {
    setSelectedFiles(Array.from(event.target.files));
  };

  return (
    <div
      className={`flex flex-col gap-1 p-2 rounded bg-gray-50 2xl:max-w-[300px] xl:max-w-[200px] lg:w-[300px]`}
    >
      {title && (
        <h2 className="text-violet-500 text-sm font-medium flex gap-2 items-center">
          <span>{title}</span>
          {tooltip && (
            <Tooltip title={tooltip}>
              <InfoRounded fontSize="small" />
            </Tooltip>
          )}
          {fetchingFiles && <CircularProgress size={20} />}
        </h2>
      )}
      <div className={''}>
        <input ref={inputRef} type="file" multiple onChange={handleFileChange} hidden />
        <div className="flex gap-2 flex-wrap md:flex-nowrap max-w-full w-full">
          <div
            onClick={handleInputClick}
            className={`${
              errorMessage ? 'border-red-500' : ''
            } w-full h-20 max-w-xs max-h-full border flex flex-col items-center justify-center gap-2 cursor-pointer hover:bg-gray-100 rounded-lg transition-all border-dotted text-gray-500 border-violet-500`}
          >
            {uploading ? (
              <>
                <CircularProgress size={20} />
                <span className="text-xs">Uploading...</span>
              </>
            ) : (
              <>
                <UploadRounded fontSize="large" color="inherit" />
                <span className="text-xs">Upload files</span>
              </>
            )}
          </div>
          {/* <SelectedFiles selectedFiles={selectedFiles} /> */}
        </div>
      </div>
      {errorMessage && <p className="text-xs text-red-500">{errorMessage}</p>}
      <UploadError uploadResults={uploadResults} />
      <UploadedFiles uploadedFiles={uploadedFiles} />
    </div>
  );
};

export default Uploader;
