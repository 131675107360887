import { useEffect, useState } from 'react';
import API from '../API';
import axios from 'axios';

function useUpload(prefix = '') {
  const [uploading, setUploading] = useState(false);
  const [uploadResults, setUploadResults] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [fetchingFiles, setFetchingFiles] = useState(false);

  const getS3PresignedUrl = API.useS3PresignedUrl();
  const getUploadedFiles = API.useS3UploadedFiles();

  const handleUpload = async () => {
    if (selectedFiles.length === 0) {
      setErrorMessage('Please select one or more PDF files to upload.');
      return;
    }

    setUploading(true);
    setUploadResults([]);
    setErrorMessage('');

    try {
      await Promise.all(
        selectedFiles.map(async file => {
          try {
            let presignedUrl;
            const formData = new FormData();

            getS3PresignedUrl(
              {
                file_name: `${prefix}${prefix ? '/' : ''}${file.name}`,
                file_type: file.type
              },
              res => {
                presignedUrl = res.data?.pre_signed_url;
                res.data?.fields.forEach(key => {
                  formData.append(key.name, key.value);
                });
                formData.append('file', file);

                axios
                  .post(presignedUrl, formData, {
                    headers: {
                      'Content-Type': 'multipart/form-data'
                    }
                  })
                  .then(response => {
                    setUploadResults(prevResults => [
                      ...prevResults,
                      { fileName: file.name, success: true }
                    ]);
                    setUploading(false);
                  });

                return { fileName: file.name, success: true };
              },
              err => {
                setErrorMessage('Could not upload files');
                setUploading(false);
                console.error(err);
              }
            );
          } catch (error) {
            setUploading(false);
            console.error('Error uploading file:', file.name, error);
            setErrorMessage('Could not upload files');
            return { fileName: file.name, success: false, error: error.message };
          }
        })
      );
    } catch (error) {
      setUploading(false);
      console.error('Error during upload process:', error);
      setErrorMessage('Failed to upload the files. Please try again.');
    }
  };

  useEffect(() => {
    if (selectedFiles.length > 0) {
      handleUpload();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFiles]);

  useEffect(() => {
    if (uploading || fetchingFiles) return;
    setFetchingFiles(true);
    getUploadedFiles(
      {},
      res => {
        setUploadedFiles(
          res.data?.files.map(file => (file.name.includes(prefix) ? file : null)).filter(Boolean)
        );
        setFetchingFiles(false);
      },
      err => {
        console.error(err);
        setFetchingFiles(false);
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploading]);

  return {
    uploading,
    uploadResults,
    errorMessage,
    selectedFiles,
    uploadedFiles,
    fetchingFiles,
    setSelectedFiles
  };
}

export default useUpload;
