import React from 'react';

function UploadError({ uploadResults }) {
  if (uploadResults.length > 0) return;

  return (
    <div className="flex flex-col gap-1">
      {uploadResults
        .filter(e => e.error !== undefined)
        .map(result => (
          <span>
            There was an error uploading the file {result.fileName}: {result.error}
          </span>
        ))}
    </div>
  );
}

export default UploadError;
