import React from 'react';
import Heading from '../Heading';
import AlertingTools from './Sections/AlertingTools.jsx';
import Enrich from './Sections/Enrich.jsx';
import ObservabilityTools from './Sections/ObservabilityTools.jsx';
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  timelineItemClasses,
  TimelineSeparator
} from '@mui/lab';
import CompletionPercentage from './Sections/CompletionPercentage.jsx';
// import HelpComponent from './HelpComponent.jsx';

const timelineDotClassName = '!bg-violet-500 !shadow-none w-8 h-8 flex items-center justify-center';

function Intelligence() {
  return (
    <div className="max-w-full">
      <Heading heading={'Doctor Droid Engine Setup'} />
      <main className="m-2 relative border rounded bg-white">
        {/* <HelpComponent /> */}
        <div className="sticky top-[80px] bg-white z-10 p-1">
          <CompletionPercentage />
        </div>
        <Timeline
          sx={{
            [`& .${timelineItemClasses.root}:before`]: {
              flex: 0,
              padding: 0
            }
          }}
        >
          <TimelineItem>
            <TimelineSeparator>
              <TimelineDot className={timelineDotClassName}>1</TimelineDot>
              <TimelineConnector className="!bg-violet-300" />
            </TimelineSeparator>
            <TimelineContent>
              <AlertingTools />
            </TimelineContent>
          </TimelineItem>
          <TimelineItem>
            <TimelineSeparator>
              <TimelineDot className={timelineDotClassName}>2</TimelineDot>
              <TimelineConnector className="!bg-violet-300" />
            </TimelineSeparator>
            <TimelineContent>
              <Enrich />
            </TimelineContent>
          </TimelineItem>
          <TimelineItem>
            <TimelineSeparator>
              <TimelineDot className={timelineDotClassName}>3</TimelineDot>
            </TimelineSeparator>
            <TimelineContent>
              <ObservabilityTools />
            </TimelineContent>
          </TimelineItem>
        </Timeline>
      </main>
    </div>
  );
}

export default Intelligence;
