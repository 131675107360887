import React from 'react';
import Uploader from '../../common/Uploader';
import CustomInputs from './CustomInputs';
import { Prefixes } from './utils/prefixes';

function Enrich() {
  return (
    <>
      <h1 className="mb-5 mt-3 text-violet-500 text-sm font-bold">
        Enrich Your Doctor Droid Engine (optional)
      </h1>
      <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-4 w-fit my-2 gap-4">
        <Uploader
          title={'Incident Reports'}
          prefix={Prefixes.INCIDENT_REPORTS}
          tooltip="These reports will help the Engine understand the pattern of incidents and the correlation between impacted components."
        />
        <Uploader
          title={'Issue Tickets'}
          prefix={Prefixes.ISSUE_TICKETS}
          tooltip="Tickets tracked in tools like JIRA, can help leverage the actions taken in the past for similar issues & potentially flag these to the engineer."
        />
        <Uploader
          title={'Service Documents'}
          prefix={Prefixes.SERVICE_DOCUMENTS}
          tooltip="Documentation about your services & product/application will help the tool understand about your product a little better."
        />
        <Uploader
          title={'On-Call SOPs'}
          prefix={Prefixes.ON_CALL_SOPS}
          tooltip="If known steps for common issues are documented anywhere, it is prioritised over the first principles thinking that is otherwise applied during automated investigation."
        />
        <div className="lg:col-span-2 xl:col-span-4">
          <CustomInputs
            tooltip={
              'Any information added here manually will be helpful in building context relative to your company. It can be anything from a description about your company/application to list of services, common issues, etc.'
            }
          />
        </div>
      </div>
    </>
  );
}

export default Enrich;
