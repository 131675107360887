import React from 'react';
import { useGetConnectorListQuery } from '../../../store/features/integrations/api/index.ts';
import IntegrationCard from '../../common/IntegrationCard/index.jsx';

const unsupportedAlertTypeEnums = ['GOOGLE_CHAT'];
const unsupportedRecommenededAlertTypeEnums = ['SLACK'];

function AlertingTools() {
  const { data: integrations, isFetching } = useGetConnectorListQuery();
  const cards = integrations?.integrations?.['Alert Channels'] ?? [];

  return (
    <>
      <h1 className="mb-5 mt-3 text-violet-500 text-sm font-bold">
        Connect atleast one alerting tool
      </h1>
      {isFetching && <p className="mx-5 text-xs">Loading integrations...</p>}
      <div className="flex flex-wrap gap-4">
        {cards
          ?.filter(e => !unsupportedAlertTypeEnums.includes(e.enum))
          .map((card, index) => (
            <IntegrationCard
              key={index}
              data={card}
              recommended={!unsupportedRecommenededAlertTypeEnums.includes(card.enum)}
            />
          ))}
      </div>
    </>
  );
}

export default AlertingTools;
