import { FilePresentRounded } from '@mui/icons-material';
import { Tooltip } from '@mui/material';
import React from 'react';

function UploadedFiles({ uploadedFiles }) {
  if (uploadedFiles.length === 0) return;

  return (
    <div className="relative max-h-[100px] md:max-h-[256px] overflow-y-auto md:w-[fit-content] w-[300px] max-w-full">
      <h2 className="text-violet-500 text-xs mb-2 sticky top-0 bg-gray-50 py-1">
        Uploaded Files ({uploadedFiles.length} file{uploadedFiles.length === 1 ? '' : 's'} uploaded)
      </h2>
      <div className="grid grid-cols-1 gap-2 w-[300px] max-w-full">
        {uploadedFiles.map((file, index) => (
          <div
            key={index}
            className="w-full p-1 rounded border text-gray-500 flex items-center justify-center overflow-hidden gap-1"
          >
            <FilePresentRounded fontSize="small" />
            <Tooltip title={file.name}>
              <span className="text-xs truncate w-full">{file.name}</span>
            </Tooltip>
            <span className="text-xs w-fit bg-violet-500 text-white p-1 rounded cursor-default">
              {file.type?.split('/')[1] ?? file.type ?? 'Unkown'}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
}

export default UploadedFiles;
