import { useEffect, useState } from 'react';
import API from '../API.js';
import { useGetConnectorListQuery } from '../store/features/integrations/api/index.ts';

const MAX_ARR_LENGTH = 10;

function calculateValueForElementLength(arr) {
  const maxValue = 100 / 3;
  const maxElements = MAX_ARR_LENGTH;
  const length = arr.length;

  const value = Math.min((length / maxElements) * maxValue, maxValue);

  return value;
}

function handleInformation(alertingTools, uploadedFiles, activeIntegrations) {
  if (alertingTools === 0) {
    return "Add atleast one alerting tool to improve the engine's contexual understanding";
  }
  if (uploadedFiles.length < MAX_ARR_LENGTH) {
    return `Upload atleast ${
      MAX_ARR_LENGTH - uploadedFiles.length
    } more files to improve the engine's contexual understanding`;
  }
  if (activeIntegrations === 0) {
    return "Add atleast one integration to improve the engine's contexual understanding";
  }
  return 'Your droid engine is ready to be used';
}

function useDroidEngineStrength() {
  const { data: integrations } = useGetConnectorListQuery();
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const getUploadedFiles = API.useS3UploadedFiles();
  const cards = integrations?.integrations?.['Alert Channels'] ?? [];
  const alertingTools = cards.find(e => e.status === 'active') ? 100 / 3 : 0;
  const activeIntegrations =
    integrations?.integrations?.allAvailableConnectors?.filter(
      e => e.status === 'active' && !cards.find(card => card.enum === e.enum)
    )?.length > 0
      ? 100 / 3
      : 0;

  useEffect(() => {
    getUploadedFiles(
      {},
      res => {
        setUploadedFiles(res.data?.files);
      },
      err => {
        console.error(err);
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const percentage =
    alertingTools + calculateValueForElementLength(uploadedFiles) + activeIntegrations;

  return {
    percentage: parseInt(percentage, 10),
    information: handleInformation(alertingTools, uploadedFiles, activeIntegrations)
  };
}

export default useDroidEngineStrength;
