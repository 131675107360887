import { Button } from '@mui/material';
import styles from './styles.module.css';
import SlackButton from '../SlackButton';
import capitalizeFirstLetter from '../../../utils/capitalize';
import { CheckCircle } from '@mui/icons-material';

function IntegrationCard({ data, recommended }) {
  const handleClick = () => {};

  const isActive = data?.status === 'active' || data?.vpc?.status === 'active';

  return (
    <div
      key={data.id}
      className={
        'w-[300px] xl:w-[350px] border rounded-md text-white overflow-hidden max-w-xs bg-white'
      }
    >
      <>
        <div className={'flex flex-col text-gray-500 gap-2 p-2'}>
          <div className="flex justify-between items-center w-full h-6">
            <img
              width={25}
              height={25}
              src={data.imgUrl}
              alt={`${data.title} Logo`}
              className="bg-cover"
            />
            <div className="flex items-center gap-1">
              {isActive ? (
                <CheckCircle className="text-green-700" />
              ) : (
                <>
                  {recommended && (
                    <span className="text-xs p-1 bg-gray-50 text-violet-500 rounded">
                      Recommended
                    </span>
                  )}
                </>
              )}
            </div>
          </div>
          <h1>{data.title}</h1>
          <h6 className={styles.desc}>{data.desc}</h6>
        </div>
      </>
      {isActive ? (
        <a href={`integrations/${data.enum.toLowerCase()}`} className={styles.button}>
          <div>View Details</div>
        </a>
      ) : (
        <a
          href={
            data?.status === 'request'
              ? 'https://calendly.com/siddarthjain/catchup-call-clone'
              : data.buttonLink
          }
          target={data?.status === 'request' ? '_blank' : '_self'}
          rel="noreferrer"
        >
          {data.enum === 'SLACK' ? (
            <SlackButton />
          ) : (
            <Button
              type="link"
              className={
                'p-1 text-center w-full h-12 !capitalize !text-base !bg-gray-100 hover:!bg-violet-100'
              }
              onClick={handleClick}
            >
              {capitalizeFirstLetter(data.buttonText.toLowerCase())}
            </Button>
          )}
        </a>
      )}
    </div>
  );
}

export default IntegrationCard;
