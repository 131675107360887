import React from 'react';
import ReactDOM from 'react-dom/client';

import * as Sentry from '@sentry/react';
import { ErrorBoundary } from '@sentry/react';

import { FrigadeTour } from '@frigade/react';

import './index.css';
import App from './App';
import { AuthProvider } from './context/AuthProvider';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { TimeRangeProvider } from './context/TimeRangeProvider';
import { FrigadeProvider } from '@frigade/react';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { Provider } from 'react-redux';
import { store } from './store/index.ts';
import posthog from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';
import { GlobalSnackbar } from './components/common/GlobalSnackbar/index.jsx';
import Loading from './components/common/Loading/index.tsx';

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: 'https://da5803388b5a4bc994725a767657c0be@o4504790581837824.ingest.sentry.io/4505233379557376',
    integrations: [new Sentry.BrowserTracing()]
  });
}

posthog.init('phc_cE3iYWHLwGcBQr08lVoyjcuPk0xCBKvS44N6DpQKzAC', {
  api_host: 'https://pumpkins.drdroid.io',
  ui_host: 'https://app.posthog.com'
});

const root = ReactDOM.createRoot(document.getElementById('root')!);
root.render(
  <FrigadeProvider
    config={{
      defaultAppearance: {
        theme: {
          colorText: '#3d3d3d',
          colorTextSecondary: '#494949',
          colorTextOnPrimaryBackground: '#fff',
          colorPrimary: '#9553FE',
          colorBorder: '#E2E2E2'
        },
        styleOverrides: {
          checklistTitle: {
            fontSize: '1.5rem',
            fontWeight: 'bold',
            zIndex: '2000'
          }
        }
      }
    }}
    publicApiKey="api_public_mlRtdnomsbbEg02XxSqKWs6YsUKtVEHZGMLJ8Yo3pkEcLiaRwygztqyAngbqGXbd"
  >
    <PostHogProvider client={posthog}>
      <Provider store={store}>
        <BrowserRouter>
          <ErrorBoundary>
            <GoogleOAuthProvider clientId="846039657995-1jinfb80uhvun1dvgftamcgu9k6gstn8.apps.googleusercontent.com">
              <AuthProvider>
                <TimeRangeProvider>
                  {/* <ErrorHandler> */}
                  <Routes>
                    <Route
                      path={'/*'}
                      element={
                        <React.Suspense fallback={<Loading />}>
                          <App />
                        </React.Suspense>
                      }
                    />
                  </Routes>
                  <GlobalSnackbar />
                  {/* </ErrorHandler> */}
                </TimeRangeProvider>
              </AuthProvider>
            </GoogleOAuthProvider>
          </ErrorBoundary>
        </BrowserRouter>
      </Provider>
    </PostHogProvider>
    <FrigadeTour flowId="flow_iiTtGMxdaOVVGsx5" tooltipPosition="auto" dismissible={true} />
    <FrigadeTour flowId="flow_LusSYD5h" dismissible={true} />
  </FrigadeProvider>
);
