import React, { useEffect, Suspense } from 'react';

import Layout from './Layout';
import BaseLayout from './BaseLayout';
import { Route, Routes } from 'react-router-dom';
import RequireAuth from './components/RequireAuth';
import PersistLogin from './components/PersistLogin';
import AxiosPrivate from './components/AxiosPrivate';
import NotFound from './pages/NotFound';
import useAuth from './hooks/useAuth.js';
import posthog from 'posthog-js';
import { useGetConnectorListQuery } from './store/features/integrations/api/index.ts';
import IncidentIntelligence from './components/IncidentIntelligence/index.js';
import Intelligence from './components/IncidentIntelligence/Intelligence.jsx';

const DataDogLogin = React.lazy(() => import('./pages/DataDogLogin'));
const SlackSignup = React.lazy(() => import('./pages/SlackSignup'));
const SlackConnect = React.lazy(() => import('./pages/SlackConnect'));
const Login = React.lazy(() => import('./pages/Login'));
const ConnectorPage = React.lazy(() => import('./components/Integration/connectors/ConnectorPage'));
const Integrations = React.lazy(() => import('./components/Integration'));
const Playbooks = React.lazy(() => import('./components/Playbooks'));
const PlaybookTriggers = React.lazy(() =>
  import('./components/Playbooks/triggers/PlaybookTriggers.jsx')
);
const CreateTrigger = React.lazy(() => import('./components/Playbooks/triggers/CreateTrigger.jsx'));
const Playground = React.lazy(() => import('./components/Playgrounds'));
const ViewPlayground = React.lazy(() => import('./components/Playgrounds/ViewPlayground'));
const PlaybooksExplore = React.lazy(() => import('./components/Playbooks/Explore'));
const InviteTeam = React.lazy(() => import('./components/InviteTeam'));
const Support = React.lazy(() => import('./components/Support'));
const PlayBookRunLogs = React.lazy(() => import('./components/Playbooks/PlayBookRunLogs'));
const ApiTokens = React.lazy(() => import('./components/Apikeys/Apikeys'));
const EditPlaybook = React.lazy(() => import('./components/Playbooks/EditPlaybook.jsx'));
const CreatePlaybook = React.lazy(() => import('./components/Playbooks/CreatePlaybook'));
const CreatePlaybookBeta = React.lazy(() => import('./components/Playbooks/create/index.jsx'));
const Insights = React.lazy(() => import('./pages/Insights'));
const AlertTypeSpecificInsights = React.lazy(() =>
  import('./pages/Insights/AlertTypeSpecificInsights')
);
const SampleAlerts = React.lazy(() => import('./pages/Insights/SampleAlerts'));

const App = () => {
  const { auth } = useAuth();
  const { data: integrations, isFetching } = useGetConnectorListQuery();
  const slackConnectorExists =
    integrations?.integrations?.allAvailableConnectors?.find(
      integration => integration.title === 'SLACK'
    ).status === 'active';

  useEffect(() => {
    if (auth.email) {
      posthog.identify(auth.email);
    }
  }, [auth]);

  useEffect(() => {
    // Hide the loading indicator
    const loader = document.querySelector('.loader-container');
    if (loader && !isFetching) {
      loader.style.display = 'none';
    }
  }, [isFetching]);

  return (
    <Routes>
      <Route element={<BaseLayout />}>
        <Route path="/signup" element={<Login />} />
        <Route path="/login" element={<Login />} />
        <Route path="/datadog-login" element={<DataDogLogin />} />
        <Route path="/slack-signup" element={<SlackSignup />} />
        <Route path="/slack-connect" element={<SlackConnect />} />
      </Route>

      <Route element={<AxiosPrivate />}>
        <Route element={<Layout />}>
          <Route path="/sample-insights" element={<SampleAlerts />} />
        </Route>
      </Route>

      <Route element={<PersistLogin />}>
        <Route element={<RequireAuth />}>
          <Route element={<AxiosPrivate />}>
            <Route path="/playbooks/create/beta" element={<CreatePlaybookBeta />} />
          </Route>
        </Route>
      </Route>

      <Route element={<PersistLogin />}>
        <Route element={<RequireAuth />}>
          <Route element={<AxiosPrivate />}>
            <Route element={<Layout />}>
              <Route
                exact
                path="/"
                element={slackConnectorExists ? <Insights /> : <SampleAlerts />}
              />
              <Route path="/insights" element={<Insights />} />
              <Route path="/alert-insights" element={<Insights />} />
              <Route path="/at-insights/:alertType" element={<AlertTypeSpecificInsights />} />
              <Route path="/sample-insights" element={<SampleAlerts />} />
              <Route path="/playbooks/explore" element={<PlaybooksExplore />} />
              <Route path="/playbooks" element={<Playbooks />} />
              <Route path="/playbooks/create" element={<CreatePlaybook />} />
              <Route path="/playbooks/:playbook_id" element={<EditPlaybook />} />
              <Route path="/playbooks/edit/:playbook_id" element={<EditPlaybook />} />
              <Route path="/playbook-runs/:playbook_run_id" element={<PlayBookRunLogs />} />
              <Route path="/playbooks/triggers/:playbook_id" element={<PlaybookTriggers />} />
              <Route path="/playbooks/triggers/create/:playbook_id" element={<CreateTrigger />} />
              <Route
                path="/playbooks/triggers/view/:playbook_id/:triggerId"
                element={<CreateTrigger />}
              />
              <Route path="/playgrounds" element={<Playground />} />
              <Route path="/playgrounds/:playground_id" element={<ViewPlayground />} />
              <Route path="/integrations" element={<Integrations />} />
              <Route path="/integrations/:id" element={<ConnectorPage />} />
              <Route path="/incident-intelligence" element={<Intelligence />} />
              <Route path="/api-keys" element={<ApiTokens />} />
              <Route path="/invite-team" element={<InviteTeam />} />
              <Route path="/support" element={<Support />} />
            </Route>
          </Route>
        </Route>
      </Route>

      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default App;
