import { CloseRounded } from '@mui/icons-material';
import Overlay from '../../Overlay';
import SelectComponent from '../../SelectComponent';
import { customInputTypes } from './utils/customInputTypes';
import { useRef, useState } from 'react';

const InputOverlay = ({ isOpen, close, saveCallback }) => {
  const [type, setType] = useState('');
  const [value, setValue] = useState('');
  const customTypeRef = useRef(null);

  const handleSubmit = () => {
    const dataType = type === 'Others' ? customTypeRef.current.value : type;
    saveCallback(dataType, value);
    close();
  };

  return (
    <Overlay visible={isOpen}>
      <div className={'relative bg-white rounded-lg px-4 py-6 max-w-xs m-auto min-w-[300px]'}>
        <div
          className="absolute top-0 right-0 m-2 hover:text-black text-gray-500 cursor-pointer transition-all"
          onClick={close}
        >
          <CloseRounded />
        </div>
        <div className={'my-4 flex flex-col gap-2'}>
          <SelectComponent
            data={customInputTypes}
            searchable
            selected={type}
            onSelectionChange={id => {
              setType(id);
            }}
            placeholder="Type of Data"
          />
          {type === 'Others' && (
            <input
              ref={customTypeRef}
              type="text"
              className="border resize-none p-1 text-xs rounded outline-none"
              placeholder="Enter your type of data"
            />
          )}
          <textarea
            rows={12}
            className="border resize-none p-1 text-xs rounded outline-none"
            placeholder="Enter your data"
            value={value}
            onChange={e => setValue(e.target.value)}
          />
        </div>
        <div className={''}>
          <button
            className={
              'text-xs bg-violet-500 text-white hover:text-violet-500 hover:bg-white transition-all p-1 rounded border border-violet-500'
            }
            onClick={() => close()}
          >
            Cancel
          </button>

          <button
            className={
              'text-xs bg-white text-violet-500 hover:bg-violet-500 hover:text-white transition-all p-1 rounded border border-violet-500'
            }
            onClick={handleSubmit}
            style={{
              marginLeft: '12px'
            }}
          >
            Save
          </button>
        </div>
      </div>
    </Overlay>
  );
};

export default InputOverlay;
