import React from 'react';

function ObservabilityTools() {
  return (
    <>
      <h1 className="mb-5 mt-3 text-violet-500 text-sm font-bold">Observability Tools</h1>
      <div className="grid grid-cols-1 lg:grid-cols-2 w-fit my-2 gap-4 text-sm">
        <span>
          Connect your observability tools to Doctor Droid Engine{' '}
          <a className="text-violet-500 hover:underline" href="/integrations">
            here
          </a>
          .
        </span>
      </div>
    </>
  );
}

export default ObservabilityTools;
