export const connectors = {
  DATADOG: 'DATADOG',
  SENTRY: 'SENTRY',
  GRAFANA: 'GRAFANA',
  GRAFANA_VPC: 'GRAFANA_VPC',
  NEW_RELIC: 'NEW_RELIC',
  SLACK: 'SLACK',
  GCM: 'GCM',
  CLOUDWATCH: 'CLOUDWATCH',
  CLICKHOUSE: 'CLICKHOUSE',
  POSTGRES: 'POSTGRES',
  EKS: 'EKS',
  AGENT_PROXY: 'AGENT_PROXY'
};
