export const models = {
  CLICKHOUSE: 'CLICKHOUSE_DATABASE',
  CLOUDWATCH_METRIC: 'CLOUDWATCH_METRIC',
  CLOUDWATCH_LOG_GROUP: 'CLOUDWATCH_LOG_GROUP',
  NEW_RELIC_ENTITY_APPLICATION: 'NEW_RELIC_ENTITY_APPLICATION',
  NEW_RELIC_ENTITY_DASHBOARD: 'NEW_RELIC_ENTITY_DASHBOARD',
  NEW_RELIC_NRQL: 'NEW_RELIC_NRQL',
  GRAFANA: 'GRAFANA_TARGET_METRIC_PROMQL',
  DATADOG: 'DATADOG_SERVICE',
  DATADOG_QUERY: 'DATADOG_QUERY',
  TEXT: 'MARKDOWN',
  POSTGRES_DATABASE: 'POSTGRES_DATABASE',
  EKS_CLUSTER: 'EKS_CLUSTER'
};
