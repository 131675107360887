import { AddRounded, InfoRounded } from '@mui/icons-material';
import React, { useState } from 'react';
import InputOverlay from './InputOverlay';
import useUpload from '../../../hooks/useUpload';
import UploadError from '../../common/Uploader/UploadError';
import UploadedFiles from '../../common/Uploader/UploadedFiles';
import { CircularProgress, Tooltip } from '@mui/material';
import { Prefixes } from './utils/prefixes';

function CustomInputs({ tooltip }) {
  const [isOverlayOpen, setIsOverlayOpen] = useState(false);
  const { setSelectedFiles, errorMessage, uploading, uploadResults, uploadedFiles, fetchingFiles } =
    useUpload();
  const files = uploadedFiles.filter(
    file => !Object.values(Prefixes).some(prefix => file.name.includes(prefix))
  );

  const handleOverlayOpen = () => {
    setIsOverlayOpen(true);
  };

  const handleCreateTXTFile = (text, name) => {
    const blob = new Blob([text], { type: 'text/plain' });
    const file = new File([blob], name, { type: 'text/plain' });
    return file;
  };

  const handleSave = (type, data) => {
    const file = handleCreateTXTFile(`${type}\n${data}`, `custom-input-${type}.txt`);
    setSelectedFiles([file]);
  };

  return (
    <div className="bg-gray-50 p-2 rounded">
      <div
        className="group mt-4 flex items-center gap-2 cursor-pointer transition-all"
        onClick={handleOverlayOpen}
      >
        {uploading || fetchingFiles ? (
          <CircularProgress size={20} />
        ) : (
          <AddRounded className="p-1 rounded-full bg-violet-500 text-white border border-violet-500 group-hover:text-violet-500 group-hover:bg-white !transition-all" />
        )}
        <h2 className="text-sm text-violet-500 font-bold flex items-center gap-2">
          <span>{uploading ? 'Uploading...' : 'Custom Inputs'}</span>
          {tooltip && (
            <Tooltip title={tooltip}>
              <InfoRounded fontSize="small" />
            </Tooltip>
          )}
        </h2>
      </div>
      {errorMessage && <p className="text-xs text-red-500">{errorMessage}</p>}
      <UploadError uploadResults={uploadResults} />
      <UploadedFiles uploadedFiles={files} />
      <InputOverlay
        isOpen={isOverlayOpen}
        close={() => setIsOverlayOpen(false)}
        saveCallback={handleSave}
      />
    </div>
  );
}

export default CustomInputs;
