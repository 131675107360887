import React from 'react';
import useDroidEngineStrength from '../../../hooks/useDroidEngineStrength';

function CompletionPercentage() {
  const { percentage, information } = useDroidEngineStrength();

  return (
    <div className="mx-5 my-2 bg-violet-50 rounded p-2">
      <h2 className="text-violet-500 font-bold text-sm">Doctor Droid Engine Strength</h2>
      <div className="flex items-center gap-2 my-2">
        <span className="text-violet-500 font-medium text-xs">{percentage}%</span>
        <div className="w-full bg-gray-200 rounded-full h-2">
          <div
            className="bg-violet-500 h-full rounded-full transition-all duration-300"
            style={{ width: `${percentage}%` }}
          ></div>
        </div>
      </div>
      {information && <p className="text-xs text-gray-500">{information}</p>}
    </div>
  );
}

export default CompletionPercentage;
