export const SOURCES = {
  CLOUDWATCH: 'CLOUDWATCH',
  CLOUDWATCH_LOGS: 'CLOUDWATCH_LOGS',
  GRAFANA: 'GRAFANA',
  GRAFANA_VPC: 'GRAFANA_VPC',
  CLICKHOUSE: 'CLICKHOUSE',
  TEXT: 'DOCUMENTATION',
  NEW_RELIC: 'NEW_RELIC',
  DATADOG: 'DATADOG',
  POSTGRES: 'POSTGRES',
  EKS: 'EKS'
};
